<template>
  <div class="component">
    <b-form-group
      label="Area"
      label-for="area"
    >
      <validation-provider
        #default="{ errors }"
        name="Area"
        rules="required"
      >
        <v-select
          v-model="data.area"
          :options="options.area"
          name="area"
          :disabled="isreadonly"
          @input="updateData"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <b-form-group
      label="Sub Area"
      label-for="subArea"
    >
      <validation-provider
        #default="{ errors }"
        name="subArea"
        rules="required"
      >
        <v-select
          v-model="data.subarea"
          name="subarea"
          label="label"
          :options="getSubareaOption"
          :disabled="isreadonly"
          :reduce="option => option.label"
          @input="updateData"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <b-form-group
      label="Unit"
      label-for="unit"
    >
      <validation-provider
        #default="{ errors }"
        name="Unit"
      >
        <b-input-group
          class="input-group-merge"
          :class="errors.length > 0 ? 'is-invalid':null"
        >
          <b-form-input
            id="unit"
            v-model="data.address_unit"
            :state="errors.length > 0 ? false:null"
            class="form-control-merge"
            name="unit"
            :disabled="isreadonly"
            @input="updateData"
          />
        </b-input-group>
      </validation-provider>
    </b-form-group>
    <b-form-group
      label="Street Number"
      label-for="street-number"
    >
      <validation-provider
        #default="{ errors }"
        name="Street Number"
        rules="required"
      >
        <b-input-group
          class="input-group-merge"
          :class="errors.length > 0 ? 'is-invalid':null"
        >
          <b-form-input
            id="street-number"
            v-model="data.address_number"
            :state="errors.length > 0 ? false:null"
            class="form-control-merge"
            name="street-number"
            :disabled="isreadonly"
            @input="updateData"
          />
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <b-form-group
      label="Street Name"
      label-for="street-name"
    >
      <validation-provider
        #default="{ errors }"
        name="Street Name"
        rules="required"
      >
        <b-input-group
          class="input-group-merge"
          :class="errors.length > 0 ? 'is-invalid':null"
        >
          <b-form-input
            id="street-name"
            v-model="data.address_street"
            :state="errors.length > 0 ? false:null"
            class="form-control-merge"
            name="street-name"
            :disabled="isreadonly"
            @input="updateData"
          />
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <b-form-group
      label="Street Direction"
      label-for="street-direction"
    >
      <validation-provider
        #default="{ errors }"
        name="Street Direction"
      >
        <v-select
          v-model="data.address_direction"
          :options="['N', 'W', 'S', 'E', 'NW', 'NE', 'SW', 'SE']"
          name="street-direction"
          :clearable="true"
          :searchable="false"
          :disabled="isreadonly"
          @input="updateData"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <b-form-group
      label="Street Type"
      label-for="street-type"
    >
      <validation-provider
        #default="{ errors }"
        name="Street Type"
        rules="required"
      >
        <v-select
          v-model="data.address_street_designation_id"
          :options="options.address_street_designation_id"
          name="street-type"
          :disabled="isreadonly"
          @input="updateData"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <b-form-group
      label="City"
      label-for="city"
    >
      <validation-provider
        #default="{ errors }"
        name="City"
        rules="required"
      >
        <v-select
          v-model="data.city"
          :options="options.city"
          name="city"
          :disabled="isreadonly"
          @input="updateData"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <b-form-group
      label="Province"
      label-for="province"
    >
      <validation-provider
        #default="{ errors }"
        name="province"
        rules="required"
      >
        <v-select
          v-model="data.province"
          name="status"
          :options="options.province"
          :disabled="isreadonly"
          @input="updateData"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <b-form-group
      label="Postal Code"
      label-for="postal-code"
    >
      <validation-provider
        #default="{ errors }"
        name="Postal Code"
        rules="required"
      >
        <b-input-group
          class="input-group-merge"
          :class="errors.length > 0 ? 'is-invalid':null"
        >
          <b-form-input
            id="postal-code"
            v-model="data.postal_code"
            :state="errors.length > 0 ? false:null"
            class="form-control-merge"
            name="postal-code"
            :disabled="isreadonly"
            @input="updateData"
          />
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BFormGroup, BInputGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    ValidationProvider,
    vSelect,
    BFormGroup,
    BInputGroup,
    BFormInput,
  },
  data() {
    return {
      lastSelectedArea: '',
    }
  },
  props: {
    data: Object,
    options: Object,
    isreadonly: Boolean,
  },
  computed: {
    getSubareaOption() {
      let opt = []

      if (this.options.areas) {
        this.options.areas.forEach(element => {
          if (element.children) {
          // eslint-disable-next-line consistent-return
            element.children.forEach(sub => {
              if (sub.label === this.data.area) {
                opt = sub.children
              }
            })
          }
        })
      }

      return opt
    },
  },
  watch: {
    getSubareaOption: {
      deep: true,
      handler() {
        if (this.lastSelectedArea !== '' && this.lastSelectedArea !== this.data.area) {
          this.data.subarea = ''
        }

        this.lastSelectedArea = this.data.area
      },
    },
  },
  methods: {
    updateData() {
      this.$emit('update-data', this.data)
    },
  },
}
</script>

<style>

</style>
