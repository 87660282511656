<template>
  <div class="component">
    <b-form-group label-for="remark">
      <b-form-textarea
        id="remark"
        v-model="data.remark"
        rows="15"
        :disabled="isreadonly"
      />
    </b-form-group>
  </div>
</template>

<script>
import {
  BFormGroup, BFormTextarea,
} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormTextarea,
  },
  props: {
    data: Object,
    isreadonly: Boolean,
  },
  methods: {
    updateData() {
      this.$emit('update-data', this.data)
    },
  },
}
</script>

<style>

</style>
