<template>
  <div class="component">
    <b-form-group
      label="Lot Size"
      label-for="lot-size"
    >
      <validation-provider
        #default="{ errors }"
        name="Lot Size"
        rules="required"
      >
        <b-input-group
          class="input-group-merge"
          :class="errors.length > 0 ? 'is-invalid':null"
        >
          <b-form-input
            id="lot-size"
            v-model="data.lot_size_sqft"
            :state="errors.length > 0 ? false:null"
            class="form-control-merge"
            name="lot-size"
            :disabled="isreadonly"
            @input="updateData"
          />
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import {
  BFormGroup, BInputGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    ValidationProvider,
    BFormGroup,
    BInputGroup,
    BFormInput,
  },
  props: {
    data: Object,
    isreadonly: Boolean,
  },
  methods: {
    updateData() {
      this.$emit('update-data', this.data)
    },
  },
}
</script>

<style>

</style>
