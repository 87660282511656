var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component"},[_c('b-form-group',{attrs:{"label":"Home Style","label-for":"home-style"}},[_c('validation-provider',{attrs:{"name":"Home Style","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"Home Style","options":_vm.options.style_of_home,"disabled":_vm.isreadonly},on:{"input":_vm.updateData},model:{value:(_vm.data.style_of_home),callback:function ($$v) {_vm.$set(_vm.data, "style_of_home", $$v)},expression:"data.style_of_home"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Beds","label-for":"beds"}},[_c('validation-provider',{attrs:{"name":"Beds","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"beds","state":errors.length > 0 ? false:null,"name":"beds","disabled":_vm.isreadonly},on:{"input":_vm.updateData},model:{value:(_vm.data.bedrooms),callback:function ($$v) {_vm.$set(_vm.data, "bedrooms", $$v)},expression:"data.bedrooms"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Baths","label-for":"baths"}},[_c('validation-provider',{attrs:{"name":"Baths","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"baths","state":errors.length > 0 ? false:null,"name":"baths","disabled":_vm.isreadonly},on:{"input":_vm.updateData},model:{value:(_vm.data.bathrooms),callback:function ($$v) {_vm.$set(_vm.data, "bathrooms", $$v)},expression:"data.bathrooms"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Year Built","label-for":"year-built"}},[_c('validation-provider',{attrs:{"name":"Year Built","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"yearBuilt","state":errors.length > 0 ? false:null,"name":"yearBuilt","disabled":_vm.isreadonly},on:{"input":_vm.updateData},model:{value:(_vm.data.year_built),callback:function ($$v) {_vm.$set(_vm.data, "year_built", $$v)},expression:"data.year_built"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Living Area","label-for":"living-area"}},[_c('validation-provider',{attrs:{"name":"Living Area","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"living-area","state":errors.length > 0 ? false:null,"name":"living-area","disabled":_vm.isreadonly},on:{"input":_vm.updateData},model:{value:(_vm.data.floor_size),callback:function ($$v) {_vm.$set(_vm.data, "floor_size", $$v)},expression:"data.floor_size"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Lot Size","label-for":"lot-size"}},[_c('validation-provider',{attrs:{"name":"Lot Size"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"lot-size","state":errors.length > 0 ? false:null,"name":"lot-size","disabled":_vm.isreadonly},on:{"input":_vm.updateData},model:{value:(_vm.data.lot_size_sqft),callback:function ($$v) {_vm.$set(_vm.data, "lot_size_sqft", $$v)},expression:"data.lot_size_sqft"}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }