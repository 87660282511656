<template>
  <div>
    <div
      v-for="(mediaTextArea, index) in mediaArray"
      :key="index"
      class="mb-1"
    >
      <div class="row d-flex align-items-center justify-content-between p-1">
        <p>Media {{ (index + 1) }}</p>

        <b-button
          variant="outline-danger"
          @click="onMediaRemoveClick(index)"
        >
          <feather-icon
            icon="Trash2Icon"
            size="16"
          />
        </b-button>
      </div>
      <b-form-group>
        <b-form-textarea
          :id="'EditMediaTextArea' + index"
          v-model="mediaArray[index]"
          placeholder="Embed"
          rows="8"
          :aria-invalid="false"
          :data-gramm_editor="false"
          @input="onTextAreaChange"
        />
      </b-form-group>
    </div>

    <b-button
      class="mb-2"
      variant="outline-primary"
      @click="onAddMediaClick"
    >
      <span>Add Media</span>
    </b-button>
  </div>
</template>

<script>
import {
  BButton, BFormGroup, BFormTextarea,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BFormGroup,
    BFormTextarea,
  },
  props: {
    mediaData: Array,
  },
  data() {
    return {
      mediaArray: [],
    }
  },
  watch: {
    mediaData: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.mediaArray = val
        }
      },
    },
  },
  created() {
    if (this.mediaData) {
      this.mediaArray = this.mediaData
    }
  },
  methods: {
    onTextAreaChange() {
      this.$emit('update-data', this.mediaArray)
    },
    onAddMediaClick() {
      this.mediaArray.push('')
      this.$emit('update-data', this.mediaArray)
    },
    onMediaRemoveClick(targetIndex) {
      this.mediaArray.splice(targetIndex, 1)
      this.$emit('update-data', this.mediaArray)
    },
  },
}
</script>

<style>

</style>
