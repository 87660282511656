<template>
  <div class="component">
    <b-form-group
      label="Status"
      label-for="status"
    >
      <validation-provider
        #default="{ errors }"
        name="Status"
        rules="required"
      >
        <v-select
          v-model="data.status"
          :options="['Active', 'Sold', 'Expired', 'Terminated']"
          name="Status"
          :clearable="false"
          :searchable="false"
          label="label"
          :disabled="isreadonly"
          @input="updateData"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <b-form-group
      label="Property Type"
      label-for="property-type"
    >
      <validation-provider
        #default="{ errors }"
        name="Property Type"
        rules="required"
      >
        <v-select
          v-model="data.property_type"
          name="Property Type"
          :searchable="false"
          :clearable="false"
          :options="['Residential Detached', 'Residential Attached', 'Land Only', 'Multifamily']"
          :disabled="isreadonly"
          @input="updatePropertyType"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

    <b-form-group
      label="Type"
      label-for="Type"
    >
      <validation-provider
        #default="{ errors }"
        name="Status"
        rules="required"
      >
        <v-select
          v-model="data.type"
          :options="options.type"
          name="Status"
          :clearable="false"
          :searchable="false"
          label="label"
          :disabled="isreadonly"
          @input="updateData"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

    <b-form-group
      label="MLS ID"
      label-for="mls-id"
    >
      <validation-provider
        #default="{ errors }"
        name="MLS ID"
      >
        <b-input-group
          class="input-group-merge"
          :class="errors.length > 0 ? 'is-invalid':null"
        >
          <b-form-input
            id="mls-id"
            v-model="data.mls_id"
            :state="errors.length > 0 ? false:null"
            class="form-control-merge"
            name="mls-id"
            :disabled="isreadonly"
            @input="updateData"
          />
        </b-input-group>
      </validation-provider>

    </b-form-group>

    <b-form-group
      label="Listed Date"
      label-for="listed-date"
    >
      <validation-provider
        #default="{ errors }"
        name="Listed Date"
        rules="required"
      >
        <b-form-datepicker
          id="listing-date"
          v-model="data.list_date"
          :disabled="isreadonly"
          locale="en"
          @input="updateData"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <b-form-group
      label="Listed Price"
      label-for="listed-price"
    >
      <validation-provider
        #default="{ errors }"
        name="Listed Price"
        rules="required"
      >
        <b-input-group
          class="input-group-merge"
          :class="errors.length > 0 ? 'is-invalid':null"
        >
          <b-form-input
            id="listed-price"
            v-model="data.asking_price"
            :state="errors.length > 0 ? false:null"
            class="form-control-merge"
            name="listed-price"
            :disabled="isreadonly"
            @input="updateData"
          />
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

    <b-form-group
      v-if="data.status === 'Sold'"
      label="Sold Price"
      label-for="sold-price"
    >
      <validation-provider
        #default="{ errors }"
        name="Sold Price"
        rules="required"
      >
        <b-input-group
          class="input-group-merge"
          :class="errors.length > 0 ? 'is-invalid':null"
        >
          <b-form-input
            id="sold-price"
            v-model="data.sold_price"
            :state="errors.length > 0 ? false:null"
            class="form-control-merge"
            name="sold-price"
            :disabled="isreadonly"
            @input="updateData"
          />
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

    <b-form-group
      label="Virtual Tour Link"
      label-for="virtual-tour"
    >
      <validation-provider
        #default="{ errors }"
        name="Virtual Tour Link"
        :rules="null"
      >
        <b-input-group
          class="input-group-merge"
          :class="errors.length > 0 ? 'is-invalid':null"
        >
          <b-form-input
            id="virtual-tour"
            v-model="data.virtual_tour"
            :state="errors.length > 0 ? false:null"
            class="form-control-merge"
            name="virtual-tour"
            :disabled="isreadonly"
            @input="updateData"
          />
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BFormGroup, BInputGroup, BFormInput, BFormDatepicker,
} from 'bootstrap-vue'

export default {
  components: {
    ValidationProvider,
    vSelect,
    BFormGroup,
    BInputGroup,
    BFormInput,
    // BCalendar,
    BFormDatepicker,
  },
  props: {
    data: Object,
    options: Object,
    changePropertyType: Function,
    isreadonly: Boolean,
  },
  methods: {
    updateData() {
      this.$emit('update-data', this.data)
    },
    updatePropertyType() {
      this.changePropertyType()
      this.updateData()
    },
  },
}
</script>

<style>

</style>
