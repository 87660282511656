<template>
  <div class="component">
    <div
      v-if="getListingSource !== 'RETS'"
      v-b-modal.upload-select-modal
      class="selectUploadContainer mb-2"
    >
      Click to Upload & Select Images
    </div>

    <div class="selectedPhotoContainer">
      <div class="row">
        <draggable
          v-model="getPreviewList"
          :sort="listingImageSource !== 'RETS'"
          @change="onOrderChange"
          @start="drag=true"
          @end="drag=false"
        >
          <div
            v-for="(singleImage, index) in getPreviewList"
            :key="index"
            class="singleImage my-2 col-lg-3"
          >
            <img
              v-if="typeof singleImage === 'object'"
              :src="singleImage.file_path ? singleImage.file_path : singleImage.location"
              :alt="singleImage.title"
              @error="onGetImageError(index)"
            >
            <img
              v-else
              :src="singleImage"
              :alt="'image' + index"
            >
            <b-avatar
              v-if="listingImageSource !== 'RETS'"
              class="deleteImgBtnContainer"
            >
              <b-button
                class="deleteImgBtn"
                @click="onDeleteBtnClick(index, singleImage)"
              >
                <feather-icon
                  icon="XIcon"
                  size="25"
                />
              </b-button>
            </b-avatar>
          </div>
        </draggable>
      </div>
    </div>

    <!-- Select & Upload -->
    <b-modal
      id="upload-select-modal"
      title="Upload & Select Images"
      size="xl"
      centered
      ok-variant="outline-primary"
      cancel-variant="outline-danger"
      @cancel="handleCancel"
      @ok="handleOk"
    >
      <b-tabs class="uploadSelectTab">
        <b-tab title="Upload Images">
          <vue-dropzone
            id="listingMeadiaDropzone"
            ref="myVueDropzone"
            :options="dropzoneOptions"
            @vdropzone-files-added="filesAdded"
          />
        </b-tab>
        <b-tab title="Media Library">
          <div class="libraryContainer">
            <div
              class="d-flex w-100 justify-content-between mb-2"
            >
              <b-input-group
                class="input-group-merge"
                style="max-width: 300px;"
              >
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="search"
                  placeholder="Search by title"
                  @input="onSearch"
                />
              </b-input-group>
            </div>
            <div
              class="row tabMediaContainer"
              @scroll="e => onTabMediaScoll(e)"
            >
              <div
                v-for="(singleImage, index) in getMediaLibrary"
                :key="index"
                class="singleImage col-lg-3 my-1"
                :class="getSelectedList.includes(singleImage) && 'singleSelected'"
                @click="onSingleImageClick(index)"
              >
                <div :class="getSelectedList.includes(singleImage) && 'selected'">
                  <img
                    :src="singleImage.file_path"
                    :alt="singleImage.title"
                  >
                </div>

                <b-avatar
                  class="selectedImgBtn"
                  variant="primary"
                >
                  <feather-icon
                    icon="CheckIcon"
                    size="15"
                  />
                </b-avatar>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import draggable from 'vuedraggable'
import {
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BTabs,
  BTab,
  BModal,
  BAvatar,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BTabs,
    BTab,
    BModal,
    BAvatar,
    draggable,
    vueDropzone: vue2Dropzone,
  },
  props: {
    photoList: Array,
    source: String,
  },
  data() {
    return {
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 300,
        maxFilesize: 0.5,
        headers: { 'My-Awesome-Header': 'header value' },
        acceptedFiles: 'image/*',
        addRemoveLinks: false,
      },
      listingImageSource: '',
      previewImageList: [],
      selectedImageList: [],
      mediaLibrary: [],
      selectedTemp: [],
      previewTemp: [],
      search: '',
      mediaTotal: 0,
      currentPage: 1,
      selectedPage: [1],
      mediaLazyLoadIndex: 1,
      mediaLastPage: 1,
    }
  },
  computed: {
    getClient() {
      return this.$store.state.clientInfo
    },
    getMediaLibrary() {
      // const temp = this.mediaLibrary.slice()

      // return temp.slice((this.currentPage - 1) * 20, this.currentPage * 20)
      return this.mediaLibrary
    },
    getPreviewList: {
      get() {
        return this.previewImageList
      },
      set(val) {
        this.previewImageList = val
        // this.$emit('select-photo', this.previewImageList)
      },
    },
    getSelectedList() {
      return this.selectedImageList
    },
    getListingSource() {
      return this.listingImageSource
    },
    getRows() {
      return Math.ceil(this.mediaTotal / 5)
    },
    getMaxFileSize() {
      return this.$store.state.MAX_FILE_UPLOAD_SIZE
    },
  },
  watch: {
    photoList: {
      immediate: true,
      deep: true,
      handler(val) {
        this.previewImageList = val
      },
    },
    source: {
      immediate: true,
      deep: true,
      handler(val) {
        this.listingImageSource = val
      },
    },
  },
  created() {
    this.$store.dispatch('media/getPhotos', { account_id: this.getClient.associated_id, keyword: '' }).then(response => {
      this.mediaLibrary = response.data.data
      this.currentPage = response.data.current_page
      this.mediaTotal = response.data.total
      this.mediaLastPage = response.data.last_page

      this.mediaLibrary.forEach(media => {
        if (this.previewImageList.filter(e => e.location === media.file_path).length > 0) {
          this.selectedImageList.push(media)
        }
      })

      this.selectedTemp = this.selectedImageList.slice()
      this.previewTemp = this.previewImageList.slice()
    }).catch(err => {
      console.error(err)
    })
  },
  methods: {
    filesAdded(file) {
      file.forEach(data => {
        if (data.size < 1024 * this.getMaxFileSize) {
          const formData = new FormData()
          formData.append('account_id', this.getClient.associated_id)
          formData.append('image', data)

          this.$store.dispatch('media/uploadPhoto', formData).then(response => {
            if (response.code === 200) {
              // this.$emit('select-photo', response.data)
              this.selectedImageList.push(response.data)
              this.selectedTemp = this.selectedImageList.slice()
              this.mediaLibrary.push(response.data)

              this.previewImageList.push(response.data)
              this.previewTemp = this.previewImageList.slice()
              this.$emit('select-photo', this.previewImageList)
            }
          }).catch(err => {
            console.error(err)
          })
        } else {
          this.$refs.myVueDropzone.removeFile(data)

          this.showToast('Upload Failed', 'AlertCircleIcon', data.name + ' exceeds the maximum file size (100KB)', 'danger', 'bottom-right')
        }
      })
    },
    onSearch() {
      this.$store.dispatch('media/getPhotos', { account_id: this.getClient.associated_id, keyword: this.search }).then(response => {
        this.mediaLibrary = response.data.data
        this.currentPage = response.data.current_page
        this.mediaTotal = response.data.total
        this.selectedPage = [1]
      }).catch(err => {
        console.error(err)
      })
    },
    onSingleImageClick(i) {
      if (this.getSelectedList.includes(this.getMediaLibrary[i])) {
        const target = this.getSelectedList.indexOf(this.getMediaLibrary[i])

        const selectedTarget = this.getPreviewList.findIndex(s => {
          if (s.location) {
            return s.location === this.getMediaLibrary[i].file_path
          }

          return s.file_path === this.getMediaLibrary[i].file_path
        })

        if (target >= 0) {
          this.selectedImageList.splice(target, 1)
        }

        if (selectedTarget >= 0) {
          this.previewImageList.splice(selectedTarget, 1)
        }
      } else {
        this.selectedImageList.push(this.getMediaLibrary[i])
      }
    },
    onDeleteBtnClick(i, single) {
      const selectedTarget = this.getSelectedList.findIndex(s => {
        if (this.previewImageList[i].location) {
          return s.file_path === this.previewImageList[i].location
        }

        return s.file_path === this.previewImageList[i].file_path
      })

      if (selectedTarget >= 0) {
        this.selectedImageList.splice(selectedTarget, 1)
      }

      this.$emit('delete-photo', single)
    },
    handleOk() {
      this.selectedImageList.forEach(img => {
        if (this.previewImageList.filter(e => e.location === img.file_path || e.file_path === img.file_path).length === 0) {
          this.previewImageList.push(img)
        }
      })

      this.previewTemp = this.previewImageList.slice()
      this.selectedTemp = this.selectedImageList.slice()
      this.$emit('select-photo', this.previewImageList)
    },
    handleCancel() {
      this.selectedImageList = this.selectedTemp
      this.previewImageList = this.previewTemp

      this.$emit('select-photo', this.previewImageList)
    },
    onOrderChange() {
      this.previewTemp = this.previewImageList.slice()
      this.$emit('select-photo', this.previewImageList)
    },
    onGetImageError(i) {
      this.previewImageList.splice(i, 1)
    },
    onPageChange() {
      this.$store.dispatch('media/getPhotos', { page: this.mediaLazyLoadIndex, account_id: this.getClient.associated_id, keyword: this.search }).then(response => {
        if (response.code === 200) {
          response.data.data.forEach(ele => {
            const found = this.mediaLibrary.some(el => el.id === ele.id)
            if (!found) {
              this.mediaLibrary.push(ele)
              if (this.previewImageList.filter(e => e.location === ele.file_path).length > 0) {
                this.selectedImageList.push(ele)
                this.selectedTemp.push(ele)
              }
            }
          })
        }
      }).catch(err => {
        console.error(err)
      })
    },
    showToast(title, icon, text, variant, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      },
      {
        position,
      })
    },
    onTabMediaScoll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        if (this.mediaLazyLoadIndex < this.mediaLastPage) {
          this.mediaLazyLoadIndex++
          this.onPageChange()
        } else {
          this.showToast('Ended', 'AlertCircleIcon', 'End of the list', 'danger', 'bottom-right')
        }
      }
    },
  },
}
</script>

<style>
.vs__open-indicator{
  display:none !important;
}

.dz-remove {
  top: unset !important;
}

.uploadPhotoTable img {
  max-width: 150px;
}

.selectUploadContainer {
  border: 1px #ccc dotted;
  background-color: #F5F5F5;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  width: 100%;
  height: 300px;
}

#previewMediaDropzone .dz-default.dz-message {
  display: none;
}

#listingMeadiaDropzone {
    min-height: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.libraryContainer {
    min-height: 550px;
}

.libraryContainer .singleImage {
  position: relative;
  cursor: pointer;
}

.singleImage img {
  max-width: 100%;
  width: 100%;
  height: 100%;
}

.selected {
  border: 2px solid #7367f0;
}

.selectedImgBtn {
  display: none;
  position: absolute;
  top: 15px;
  left: 30px;
}

.singleSelected .selectedImgBtn{
  display: block;
}

.selectedPhotoContainer .row > div {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.tabMediaContainer {
  max-height: 500px;
  overflow: auto;
}

.singleImage .deleteImgBtnContainer {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  color: #fff !important;
}

.singleImage .deleteImgBtnContainer .deleteImgBtn{
  background: rgba(0,0,0, .5) !important;
}

.singleImage .deleteImgBtnContainer .deleteImgBtn:hover{
  background: rgba(0,0,0, 1) !important;
}

</style>
